<template>
  <div
    class="box box_square"
    @click="navigateToPage()"
    :style="{backgroundColor: colors}"
  >
    <div class="box-square">
      <div class="box-rank">
        <div class="box-rank__icon"></div>
        <div class="box-rank__number">
          {{ rank }} <span>{{ abbrevs }}</span>
        </div>
      </div>

      <div class="box-info">
        <div class="box-info__title">The {{ title }} Quilt</div>
        <div class="box-info__details">
          <div class="box-text_small">artworks</div>
          <div class="box-text_medium">{{ artworks }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    charityId: {
      required: true
    },
    rank: {
      required: true
    },
    title: {
      required: true
    },
    artworks: {
      required: true
    }
  },
  methods: {
    navigateToPage() {
      this.$router.push({ name: 'entertainmentforchange', params: { id: this.charityId } })
    }
  },
  computed: {
    abbrevs() {
      switch (this.rank) {
        case 1:
          return 'st'
        case 2:
          return 'nd'
        case 3:
          return 'rd'
        default:
          return ''
      }
    },
    colors() {
      switch (this.rank) {
        case 1:
          return '#662482'
        case 2:
          return '#1E70B7'
        case 3:
          return '#94C01F'
        default:
          return '#1E70B7'
      }
    }
  }
}
</script>