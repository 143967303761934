<template>
  <div class="gu">
    <el-row>
      <el-col :lg="6" :md="6" :sm="12" :xs="24" class="content">
        <div class="logo-big red">
          <img src="/img/sponsors/gu.png" class="img-responsive">
        </div>
      </el-col>

      <el-col :lg="8" :md="10" :sm="12" :xs="24" class="content">
        <div class="copy" @click="$router.push({ path:`/quilt` })">
          <div class="padding">
            <p>The Virtual Quilt invites kids of all ages to explore, grow and become part of the exhibit. The Quilt continues to evolve as a virtual installation giving its users a passport to become a citizen of the world.</p><br/>

            <p>Our education resources serve all ages and focus on authenticity, learned subjectivity, and experiential activities. Exercise articulate artistic perspectives and help participants communicate and think critically while shaping their point of view.</p><br/>

            <p>All educational activities can be applied to any age level. By interacting on the quilt during discussions, lively interaction advances the Virtual Quilt’s motto: there is an artist in us all.</p><br/>
            
            <p class="strong textblue">Expand your experience by adding these exercises into your "life curriculum."</p>
          </div>
        </div>
      </el-col>

      <el-col :lg="5" :md="5" :sm="24" :xs="24" class="courses">
      <div>
            <el-row>
              <el-col :md="20">
                <a href="/pdf/Virtual_Quilt___Hands-On_Activities.pdf" target="_blank">
                  <div class="block purple" >
                      <h5>Creative, Hands-On Activities</h5>
                      <p class="underlined">Learn More</p>
                      <Arrow class="arrow" />
                  </div>
                </a>
              </el-col>
              <el-col :md="20">
                <a href="/pdf/Virtual_Quilt___Interactive_Activities.pdf" target="_blank">
                  <div class="block green">
                    <h5>Interactive Hybrid Activities</h5>
                    <p class="underlined">Learn More</p>

                    <div class="kentucky">
                      <a href="https://www.kentuckyteacher.org/subjects/visual-performing-arts/2021/12/the-virtual-quilt-offering-unique-digital-arts-education-experience/" target="_blank">
                        <p>As seen on</p>
                        <img src="img/logos/kentucky_teacher.png" class="img-responsive" />
                      </a>
                    </div>
                    <Arrow class="arrow" />
                  </div>
                </a>
              </el-col>
              <el-col :md="20">
                <a href="/pdf/Virtual Quilt___Discussion_and_Writing_Assignments.pdf" target="_blank">
                  <div class="block blue">
                    <h5>Discussion and Creative Writing Topics</h5>
                    <p class="underlined">Learn More</p>
                    <Arrow class="arrow" />
                  </div>
                </a>
              </el-col>
            </el-row>
          </div>
        </el-col>
    </el-row>
  </div>
</template>

<script>
  import Arrow from '@/assets/Arrow.svg';

  export default {
    components: {
      Arrow
    }
  }
</script>


