<template>
  <div class="concept">
    <el-row>

      <el-col :lg="6" :md="6" :sm="12" :xs="24" class="content">
        <div class="logo-big">
          <MainLogo class="logo" />
        </div>
      </el-col>
      <el-col :lg="8" :md="10" :sm="12" :xs="24" class="content">
        <div class="copy" @click="$router.push({ path:`/quilt` })">
          <div class="padding">
            <h3>Creating an ever-changing, never ending art installation.</h3>
            <p>A mosaic of self expression and altruistic connection via art. The Quilt’s threads connect artists to their work and encourage others to share their interpretation of art. The Quilt welcomes everyone from emerging artists and creators to art enthusiasts and educators, to explore the kaleidoscope of the Virtual Quilt and spark creativity and self discovery.</p><br/>
            <p class="strong textgreen">So express yourself! There is an artist in us all.</p>
            <Arrow class="arrow" />
          </div>
        </div>
      </el-col>

      <el-col :lg="5" :md="5" :sm="24" :xs="24" class="logos">
        <div class="sponsors">
          
          <el-row class="hidden-md-and-up">
            <el-col :sm="8" :xs="8">
              <a href="https://groovalution.com" target="_blank">
                <img src="/img/sponsors/groovalution.jpg" class="img-responsive">
              </a>
            </el-col>
            <el-col :sm="8" :xs="8">
              <a href="https://groovalution.com/pages/give-into-the-groove" target="_blank">
                <img src="/img/sponsors/gitg.jpg" class="img-responsive">
              </a>
            </el-col>
            <!-- <el-col :sm="8" :xs="8">
              <a href="https://www.elleeven.com" target="_blank">
                <img src="/img/sponsors/hot_sauce.jpg" class="img-responsive">
              </a>
            </el-col> -->
            <el-col :sm="8" :xs="8">
              <a href="https://radiolex.us/" target="_blank">
                <img src="/img/sponsors/radio_lex.jpg" class="img-responsive">
              </a>
            </el-col>
            <!-- <el-col :sm="8" :xs="8">
              <a href="https://www.elleeven.com" target="_blank">
                <img src="/img/sponsors/elleeven.jpg" class="img-responsive">
              </a>
            </el-col> -->
          </el-row>

          <div class="hidden-sm-and-down">
            <el-row>
              <el-col :md="12">
                <a href="https://groovalution.com" target="_blank">
                  <img src="/img/sponsors/groovalution.jpg" class="img-responsive">
                </a>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="{span:12,push:12}">
                <a href="https://groovalution.com/pages/give-into-the-groove" target="_blank">
                  <img src="/img/sponsors/gitg.jpg" class="img-responsive">
                </a>
              </el-col>
            </el-row>
            <el-row>
              <!-- <el-col :md="12">
                <a href="https://www.elleeven.com" target="_blank">
                  <img src="/img/sponsors/hot_sauce.jpg" class="img-responsive">
                </a>
              </el-col> -->
              <el-col :md="12">
                <a href="https://radiolex.us/" target="_blank">
                  <img src="/img/sponsors/radio_lex.jpg" class="img-responsive">
                </a>
              </el-col>
            </el-row>
            <!-- <el-row>
              <el-col :md="{span:12,push:12}">
                <a href="https://www.elleeven.com" target="_blank">
                  <img src="/img/sponsors/elleeven.jpg" class="img-responsive">
                </a>
              </el-col>
            </el-row> -->
          </div>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import MainLogo from '@/assets/Logo.svg';
  import Arrow from '@/assets/Arrow.svg';

  export default {
    components: {
      MainLogo,
      Arrow
    }
  }
</script>


