<template>
  <div class="view charity" :style="cssVars">
    <div class="box">
      <div class="box-rect">
        <div class="box-text__wrapper">
          <div @click="$router.push({ path:`/doodlefordollars` })">
            <Arrow class="box-arrow_back" />
          </div>

          <div class="box-text box-text_large">
            THE {{ charityData.name.toUpperCase() }} QUILT
          </div>
        </div>
      </div>
    </div>

     <el-dialog
      v-loading="loading"
      :visible="loadedModal"
      :before-close="closeModalForEntertainment"
      :close-on-click-modal="false"
      width="80%"
      class="modal"
    >
      <AssetView />
    </el-dialog>

    <div class="charity__wrapper">
      <!-- Slider .... -->
      <div
        class="slider"
        v-if="charityData.artworks && charityData.artworks.length > 0"
      >
        <div class="slider__wrapper">
          <div
            class="slider__item"
            v-for="(x, index) in charityDataPerPage"
            :key="index"
          >
            
            <el-image
              class="slider__blob image-clickable"
              v-if="x.filetype == 'image'"
              :src="x.image_blob"
              fit="cover"
              lazy
              @click="loadModalOnClick(x)"
            />
            
            <el-image
              class="slider__blob"
              v-if="x.filetype == 'video'" 
              :src="x.video_thumb"
              fit="cover"
              lazy
            />
          </div>
        </div>

        <div class="pagination">
          <el-pagination
            layout="prev, pager, next"
            :total="charityData.artworks.length"
            :page-size="pageSize"
            v-model="currentPage"
            @current-change="handlePageChange"
          />
        </div>
      </div>

      <div class="charity__details">
        <div class="charity__text">
          <span
            v-for="(line,lineNumber) of charityData.description.split('\n')" 
            v-bind:key="lineNumber" >
            {{ line }}<br/>
          </span>
        </div>

        <!-- button -->
        <div
          class="box-button box-button_blue"
          @click="startSharing"
        >
          <span>Start Doodling for Non Profits Today!</span>
          <Arrow class="box-arrow box-arrow_blue" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.charity {
  background-color: var(--bg-color);
}
.image-clickable {
    cursor: pointer;
}
</style>

<script>
import Arrow from '@/assets/Arrow.svg'
import axios from 'axios'
import { mapState, mapMutations, mapActions } from 'vuex'
import AssetView from '@/components/AssetView'
  
export default {
  components: {
    Arrow,
    AssetView
  },
  data() {
    return {
      charityData: {},
      currentPage: 1,
      pageSize: 20,
      loadedModal: false
    }
  },
  mounted() {
    const {id} = this.$route.params
    axios
      .get(`${process.env.VUE_APP_API_URL}/charity/${id}`)
      .then((res) => {
        this.charityData = res.data.data
      })
      .catch((err) => console.log(err))
  },
  computed: {
    ...mapState([
      'loading',
      'howToModal',
      'listModal',
    ]),
    cssVars() {
      var backgroundColor = [
        "#1E70B7",
        "#2fbbbd",
        "#eb1c00",
        "#A5B449",
        "#058962",
        "#013371",
        "#972EA5",
        "#DCA633",
        "#20136A",
        "#39075A",
        "#E16289",
        "#C39BD2",
        "#28D12B"
      ];
      console.log(backgroundColor[this.charityData.id]);
      return {
        '--bg-color': backgroundColor[this.charityData.id]
      }
    },
    totalNumberOfPages() {
      if (!this.charityData.artworks) return 1

      return Math.ceil(this.charityData.artworks.length / this.pageSize)
    },
    charityDataPerPage() {
      if (!this.charityData.artworks) return []

      const currentPageIndex = this.currentPage - 1
      const startIndex = currentPageIndex * this.pageSize
      const endIndex = startIndex + this.pageSize

      return this.charityData.artworks.slice(startIndex, endIndex)
    }
  },
  methods: {
    ...mapMutations([
      'setLoading',
      'openFormModal',
      'setAsset',
      'openListModal',
      'closeListModal',
    ]),

    ...mapActions([
        'getAssetDetails',
    ]),

    handlePageChange(page) {
      this.currentPage = page
    },
    startSharing: function () {
      this.openFormModal()
    },
    loadModalOnClick: function(asset) {
        this.setAsset(asset)
        this.loadedModal = true;
    },

    closeModalForEntertainment: function() {
        this.loadedModal = false;
    },

    loadModal: function() {

    },
  }
}
</script>