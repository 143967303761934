<template>
  <div
    class="box-list-item"
    :class="{'box-list-item_label': isLabel}"
    @click="navigateToPage()"
    :disabled="isLabel"
  >
    <div class="box-list-item__place">{{ place }}</div>
    <div class="box-list-item__title">{{ showQuiltName(title) }}</div>
    <div class="box-list-item__artworks box-text_medium">{{ artworks }}</div>
  </div>
</template>

<script>
export default {
  props: {
    charityId: {
      required: false
    },
    place: {
      required: true
    },
    title: {
      required: true
    },
    artworks: {
      required: true
    },
    isLabel: {
      type: Boolean
    }
  },
  methods: {
    navigateToPage() {
      if (this.title === 'The Give Into The Groove Foundation') {
        window.location.href = 'https://groovalution.com/pages/give-into-the-groove';
      } else {
        this.$router.push({ name: 'entertainmentforchange', params: { id: this.charityId } });
      }
    },

    showQuiltName(string) {
        if (string.startsWith('The')) {
            return string + " Quilt";
        } else {
            return "The " + string + " Quilt";
        }
    }
  }
}
</script>