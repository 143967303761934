<template>
  <div class="view doodle">
    <div class="box">
      <div class="box-rect">
        <img src="/img/icons/favicon-32x32.png" class="box-logo">

        <div class="box-text__wrapper">
          <div class="box-text">
            Doodle for Dollars
          </div>

          <div
            class="box-button box-button_short"
            @click="showLearnMore = !showLearnMore"
          >
            <span>Learn more</span>
            <Arrow class="box-arrow_black" />
          </div>
        </div>
      </div>
    </div>

    <div  v-if="showLearnMore" class="box-wrapper learn-more">
        <el-col :lg="12" :md="24" :sm="24" :xs="24" class="content">
          <div class="copy" >
            <div class="padding">
              <p class="mb-10">The <a href="https://groovalution.com/pages/give-into-the-groove">Give Into the Groove Foundation</a> was founded by ellee ven in 2001 for the purpose of encouraging people of all ages and world cultures to explore and create art. The organizations from the Virtual Quilt’s drop down menu have been selected as outstanding non profits that foster the goals of the Groovalution and The Give Into the Groove Foundation by their commitment to art education, self-expression, and respect for all of humanity. As part of the Foundation’s philanthropic efforts, the Groovalution team encourages all of the participants who share their art to The Virtual Quilt to designate which non profit they want to support with their creation.</p>
              <p>The story of Doodles for Dollars is easy to understand and share with everyone. We have selected 11 outstanding non-profits that foster the goals of creativity over everything and kindness for humanity. With the sharing of your creations we can all help to spread the message of art for art's sake. When you share, you can select which non-profit you would like to designate you art to count towards.</p>
            </div>
          </div>
        </el-col>
    </div>

    <div  v-else class="box-wrapper no-learn-more">
      <!-- first three ranks -->
      <div class="box-wrapper__top">
        <RankBox
          v-for="(x, i) in topCharities"
          :key="x.id"
          :charityId="x.id"
          :rank="i+1"
          :title="x.name"
          :artworks="x.artworks_count"
        />
      </div>

      <!-- list of other ranks -->
      <div class="box-wrapper__other">
        <div class="box-list">
          <!-- label -->
          <div class="box-list__label">
            <BoxListItem
              :place="'place'"
              :artworks="'artworks'"
              :title="'non profit'"
              :isLabel="true"
            />
          </div>

          <!-- list of items -->
          <div class="box-list__ranks">
            <BoxListItem
              v-for="(x, i) in otherCharities"
              :key="x.id"
              :charityId="x.id"
              :place="i+4"
              :artworks="x.artworks_count"
              :title="x.name"
            />
          </div>
        </div>

        <!-- button -->
        <div
          class="box-button"
          @click="startSharing"
        >
          <span>Start Doodling for Non Profits Today!</span>
          <Arrow class="box-arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '@/assets/Arrow.svg';
import RankBox from '@/components/RankBox.vue'
import BoxListItem from '@/components/BoxListItem.vue'
import axios from 'axios'
import { mapState, mapMutations } from 'vuex'
  
export default {
  components: {
    Arrow,
    RankBox,
    BoxListItem
  },
  data() {
    return {
      showLearnMore: false,
      charities: [],
      topCharities: [],
      otherCharities: []
    }
  },
  computed: {
    ...mapState([
      'loading',
      'howToModal'
    ])
  },
  methods: {
    ...mapMutations([
      'setLoading',
      'openFormModal',
      'closeHowToModal'
    ]),

    startSharing: function () {
      this.closeHowToModal()
      this.openFormModal()
    },

    closeModal: function () {
      this.closeHowToModal()
    },

  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/charity?per_page=48`)
      .then((res) => {
        this.charities = res.data.data

        if (this.charities) {
          const TOP_CHARITIES_LIMIT = 3
          //const OTHER_CHARITIES_LIMIT = 8

          this.topCharities = this.charities.filter((_, index) => index < TOP_CHARITIES_LIMIT)
          this.otherCharities = this.charities.filter((_, index) => index >= TOP_CHARITIES_LIMIT)
        }
      })
      .catch((err) => console.log(err))
  }
}
</script>
